<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>客服配置</div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label="客服微信二维码" prop="logo"><el-upload class="avatar-uploader" :action="$api.zfUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                        <img v-if="info.erwm" :src="info.erwm" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>
                    <el-form-item label="联系电话" prop="mobile"><el-input placeholder="请输入内容" v-model="info.lianxdh"></el-input></el-form-item>

                    <el-form-item label="关联微信号" prop="F_IN_YONGHGZH_ID">
                        <el-select v-model="info.F_IN_YONGHGZH_ID" filterable placeholder="请选择对应微信号" @change="selectChange()">
                            <el-option label="请选择对应微信号" :value="0"></el-option>
                            <el-option v-for="(v,k) in list"  :label="v.nickname" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                    </el-form-item>



                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                info:{
                    guanggt:''
                },
                upload_headers:{},
                haib:'',
                list:[],
            };
        },
        watch: {},
        computed: {},
        methods: {
            selectChange:function(){
                this.$forceUpdate();
            },
            submitForm:function(e){
                this.$refs[e].validate(res=>{
                    if(res){
                        // Http 请求
                        this.$post(this.$api.kefConfig,this.info).then(postRes=>{
                            if(postRes.code == 200){
                                this.$message.success("编辑成功");
                                this.get_web_config();
                            }else{
                                this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            handleAvatarSuccess(res) {
                this.info.erwm = res.data;
                this.$forceUpdate();
            },
            get_web_config(){
                this.$get(this.$api.kefConfig).then(res=>{
                    console.log(666)
                    console.log(res.data)
                    console.log(666)
                    this.info  = res.data.oss_config;
                    this.list = res.data.list;
                });
            },

        },
        created() {
            this.get_web_config();
            this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到
        },
        mounted() {}
    };
</script>
<style lang="scss" scoped>

</style>